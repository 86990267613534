import React from 'react'


import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'


export const locationPL = {
    header:
    {
        title: 'FlitpTiM',
        subtitle: <>
            <p>
                Nasza praca, to nasza pasja. Dlatego nasza firma jest tak różnorodna, jak nasze pasje.
                Od 1997 roku nieprzerwanie pracujemy dla naszych klientów dbając o jak najlepszą ofertę
            </p>
        </>,
        it: 'IT Outsourcing',
        code: 'code',
        knx: 'Inteligentny Dom ',
        uro: 'Urodzinki.pl',
        kamp: 'Kamperkiem.pl',
        // contact: 'Kontakt',
    },
    it:
    {
        body: <>
            <h2 className="major">IT OUTSOURCING</h2>
            {/* <span className="image main"><img src={pic01} alt="" /></span> */}
            <p>
                Nie chcesz marnować jednego pokoju dla działu IT i kupować co chwilę najnowszego komputera dla informatyka?<br />
                Wybierz naszą ofertę. Zajmujemy się kompleksową obsługą informatyczną firm.<br />
                Możesz liczyć na profesjonalne wsparcie techniczne oraz doradztwo przy zakupach sprzętu i oprogramowania.<br />
            </p>
            <p>
                Zainteresowany współpracą?<br />
                Zadzwoń:&nbsp;
                <span style={{ letterSpacing: '0.02rem', fontSize: "1.2rem" }}>+48 605345474</span>
                <br />
            </p>
        </>,
    },
    code:
    {
        body: <>
            <h2 className="major">Code</h2>
            {/* <span className="image main"><img src={pic03} alt="" /></span> */}
            <p>
                Tworzymy oprogramowanie dla klientów <b>eCommerce</b> wspomagające automatyzację pracy. Nasze rozwiązania współpracują z m.in. <b>Subiektem, Sello, Magento</b>. <br />
                Przeprowadzamy również dogłębne analizy danych za pomocą algorytmów <b>Machine Learning</b> pozawalające na predykcję sprzedaży czy personalizację klientów.<br />
                Dla tych rozwiązań zaprojektujemy  dedykowane strony internetowe bądź progresywne aplikacje webowe.
            </p>
            <p>
                Zainteresowany współpracą?<br />
                Zadzwoń:&nbsp;
                <span style={{ letterSpacing: '0.02rem', fontSize: "1.2rem" }}>+48 723727723</span>
                <br />
                
            </p>
        </>,
    },
    knx:
    {
        body: <>
            <h2 className="major">Inteligentny Dom</h2>
            {/* <span className="image main"><img src={pic02} alt="" /></span> */}
            <p>
                Dzięki technologiom możesz poczuć się wyjątkowo w swoim domu.<br/>
                Inteligentny dom zadba o Twoje bezpieczeństwo i wygodę.<br/>
                Twój wirtualny Jan będzie pamiętał o wszystkim za Ciebie i robił to co lubisz i kiedy lubisz…<br/>
                Rolety, zasłony, oświetlenie, alarm, ogrzewanie, klimatyzacja, podlewanie - zajmie się tym, czym zechcesz.<br/>
            </p>
            <p>
                Zainteresowany współpracą?<br />
                Zadzwoń:&nbsp;
                <span style={{ letterSpacing: '0.02rem', fontSize: "1.2rem" }}>+48 605345474</span>
                <br />
            </p>
        </>,
    },
}

