import React from 'react'
import PropTypes from 'prop-types'
import FliptimIcon from "../../img_icons/logo FlipTiM.svg";
// import logo from './logo.svg'

import localization from '../localization/index'

const Header = (props) => (
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
        <div className="logo">
            <img className="icon" src={FliptimIcon} />
            {/* <span className="icon fa-diamond"></span> */}
        </div>
        <div className="content">
            <div className="inner">
                {/* <h1>{localization.header.title}</h1> */}

                {localization.header.subtitle}

            </div>
        </div>
        <nav>
            <ul>
                {/* nowa karta urodzinki */}
                <li><a href="javascript:;" onClick={() => { props.onOpenArticle('it') }}>{localization.header.it}</a></li>
                <li><a href="javascript:;" onClick={() => { props.onOpenArticle('code') }}>{localization.header.code}</a></li>
                <li><a href="javascript:;" onClick={() => { props.onOpenArticle('knx') }}>{localization.header.knx}</a></li>
                {/* <li><a href="http://kamperkiem.pl" target="_blank" >{localization.header.kamp}</a></li> */}
                <li><a href="https://sklep.urodzinki.pl" target="_blank" >{localization.header.uro}</a></li>
                {/* <li><a href="javascript:;" onClick={() => { props.onOpenArticle('contact') }}>{localization.header.contact}</a></li> */}
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
